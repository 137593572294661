<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>条目关联管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
      <Tabs>
          <TabPane label="新建关联" icon="md-document">
            <div class="clause-i">
              <p class="c-title">已选择的条目</p>
              <div>
                <Table border :columns="columnsFormatData2" :data="choosedClause" no-data-text="暂无数据">
              </Table>
              </div>
              
              <div class="button-wrapper">
                <Button @click="openModal('addClause')" size="large" style="margin-right: 30px"
                  >开始添加条目</Button
                >
                <Button
                  @click="startConfirm"
                  size="large"
                  >提交</Button
                >
              </div>
            </div>
          </TabPane>
          <TabPane label="已存在的关联" icon="md-done-all">
            <normal-list
              :columnsFormatData="columnsFormatLink"
              askData="clauseLinkList"
              main_width="1260px"
              :refreshData="update_count"
            ></normal-list>
          </TabPane>
        </Tabs>
        </div>
      
      <Modal
        v-model="modalSwicth.addClause"
        :width="1380"
        :mask-closable="false"
        @on-cancel="closeModal('addClause')"
        :styles="{top: '10px'}"
      >
        <p slot="header" class="sc-header">
          <Icon type="ios-list-box" />
          <span>请选择要关联的条目</span>
        </p>
        <i
          class="ivu-icon ivu-icon-ios-close"
          slot="close"
          @click="closeModal('addClause')"
        ></i>
        <div class="sc-content-wrapper">
          <div>
            <clause-list
              :columnsFormatData="columnsFormatData"
              askData="clauseList"
              main_width="1260px"
              v-model="selectedRow"
            ></clause-list>
          </div>
        </div>
        <div slot="footer">
          <Button @click="handleAdd" size="large" style="margin-left: 8px"
            >添加</Button
          >
          <Button
            size="large"
            @click="closeModal('addClause')"
            class="cancel-button"
            >关闭</Button
          >
        </div>
      </Modal>
      <Modal
        v-model="modalSwicth.confirmName"
        :width="600"
        :mask-closable="false"
        @on-cancel="closeModal('confirmName')"
      >
        <p slot="header" class="sc-header">
          <Icon type="ios-list-box" />
          <span>请确认新的要求文件名称</span>
        </p>
        <i
          class="ivu-icon ivu-icon-ios-close"
          slot="close"
          @click="closeModal('confirmName')"
        ></i>
        <div class="sc-content-wrapper">
          <div class="name-input-wrapper">
            <input type="text" v-model="newFileName"/>
          </div>
        </div>
        <div slot="footer">
          <Button
            @click="handleSubmit"
            :loading="loadingStatus"
            size="large"
            >{{ loadingStatus ? "Uploading" : "确认提交" }}</Button
          >
          <Button
            size="large"
            @click="closeModal('confirmName')"
            class="cancel-button"
            >关闭</Button
          >
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import clauseList from "@/components/Common/ClauseList.vue";
import normalList from "@/components/Common/NormalList.vue";

let userToken = localStorage.getItem("current_user_token");
// let authData = JSON.parse(localStorage.getItem("authData"));

export default {
  name: "clauseRelation",
  components: {
    clauseList,
    normalList
  },
  props: {
  },
  data() {
    return {
      choosedClause:[],
      update_count:1,
      selectedRow:[],
      newFileName:'',
      columnsFormatData: [
        {
          type: "selection",
          width: 60,
          align: "center",
          fixed: "left"
        },
        {
          title: "标准",
          align: "center",
          render: (h, p) => {
            return h(
              "p",
              {
              },
              p.row.standard_no+'《'+p.row.standard_name+'》'
            );
          }
        },
        {
          title: "条目号",
          width: 200,
          align: "center",
          key: "clause_no",
        },
        {
          title: "条目内容",
          key: "content",
          align: "center",
        },
        {
          title: "要求文件名称",
          key: "file_name",
          align: "center",
          width: 200,
        },
        {
          title: "涉及专业",
          key: "major",
          align: "center",
          render: (h, p) => {
            let majorList = [];
            for(let i of p.row.major.split(';')){
              for(let j of JSON.parse(i)){
                majorList.push(j.name);
              }
            }
            return h(
              "p",
              {},
              majorList.join(',')
            );
          }
        },
      ],
      columnsFormatData2: [
        {
          title: "标准",
          align: "center",
          render: (h, p) => {
            return h(
              "p",
              {
              },
              p.row.standard_no+'《'+p.row.standard_name+'》'
            );
          }
        },
        {
          title: "条目号",
          width: 200,
          align: "center",
          key: "clause_no",
        },
        {
          title: "条目内容",
          key: "content",
          align: "center",
        },
        {
          title: "要求文件名称",
          key: "file_name",
          align: "center",
          width: 200,
        },
        {
          title: "涉及专业",
          key: "major",
          align: "center",
          render: (h, p) => {
            let majorList = [];
            for(let i of p.row.major.split(';')){
              for(let j of JSON.parse(i)){
                majorList.push(j.name);
              }
            }
            return h(
              "p",
              {},
              majorList.join(',')
            );
          }
        },
        {
          title: "操作",
          align: "center",
          width: 90,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red" },
                  on: {
                    click: () => {
                      let index = this.choosedClause.findIndex(val=>val.id===params.row.id);
                      this.choosedClause.splice(index,1);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      columnsFormatLink:[
        {
          title: "涉及条目",
          key: "major",
          align: "center",
           render: (h, p) => {
            let clauseList = JSON.parse(p.row.clauses);
            return h(
              "div",
              clauseList.map(val=>{
                return h('p',{},
                  val.standard_no+'《'+val.standard_name+'》'+val.clause_no+'：'+val.content+'; '+val.file_name
                );
              })
            );
          }
        },
        {
          title: "创建人",
          width: 200,
          align: "center",
          key: "creator",
        },
        {
          title: "创建时间",
          key: "create_time",
          width: 200,
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: 90,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      modalSwicth: {
        addClause: false,
        confirmName:false,
      },
      loadingStatus: false,
    };
  },
  watch: {},
  computed: {},
  created() {
  },
  methods: {
    openModal(value) {
      this.modalSwicth[value] = true;
    },
    closeModal(value) {
      this.modalSwicth[value] = false;
    },
    
    handleReset() {
      for (let item in this.formItem) {
        this.formItem[item] = "";
      }
      this.file = null;
    },
    handleAdd(){
      for(let i of this.selectedRow){
        if(!this.choosedClause.find(val=>val.id===i.id)){
          this.choosedClause.push(JSON.parse(JSON.stringify(i)));
        }
      }
      this.modalSwicth.addClause = false;
    },
    startConfirm(){
      if(this.choosedClause.length>0){
        this.newFileName = this.choosedClause[0]['file_name'];
      this.openModal('confirmName')
      }else{
        this.$Message.warning('尚未选择条目！');
      }
      
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除：",
        content: "<p>该条目关联关系？</p>",
        onOk: () => {
          this.recordFormatDel(value.id);
        },
        onCancel: () => {},
      });
    },
    recordFormatDel(id) {
      let form = new FormData();
      form.append("userToken", userToken);
      form.append("askData", "delLink");
      form.append("id", id);
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.$axios
        .post(this.$url + "/clause_manage", form, config)
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    handleSubmit() {
      if(this.newFileName.trim()){
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "adjustClauseRelation");
          form.append("choosedClause", JSON.stringify(this.choosedClause));
          form.append("newFileName", this.newFileName.trim());
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/clause_manage", form, config)
            .then((response) => {
              this.handleReset();
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("操作成功！");
                location.reload();
              } else {
                this.$Message.error(response.data.errorInfo);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        }else{
          this.$Message.error("新文件名称不能为空！");
        }
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.clause-i{
  width:100%;
  margin:0 auto;
  text-align: center;
  padding: 10px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 40px;
  width: 1280px;
}

.editor-title,
.preview-title {
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.re-l {
  min-height: 750px;
  color: #000;
  border: #aaa solid 1px;
  box-shadow: 1px 2px 1px #aaa;
  text-align: center;
  position: relative;
  margin: 5px;
  padding: 0 10px;
}

.form-wrapper {
  text-align: left;
  padding: 80px 120px 80px 50px;
}

.c-title{
  padding: 20px;
  font-size: 14px;
}

.button-wrapper{
  padding: 30px;
}

.name-input-wrapper{
  text-align: center;
}

.name-input-wrapper input{
  padding: 5px 10px;
  outline: none;
  border:none;
  border-bottom: 1px solid #999;
  width:300px;
  text-align: center;
}
</style>